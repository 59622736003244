import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import {
  makeStyles,
  Drawer,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemText,
} from "@material-ui/core"

import logo from "../images/logo-white.png"
import useIsMobile from "../hooks/useIsMobile"

const drawerWidth = 320

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  drawerContainer: {
    overflow: "auto",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0px",
  },
  logo: {
    marginBottom: 0,
    width: "75%",
  },
  divider: {
    backgroundColor: "#555555",
    margin: "10px 0px",
    width: "80%",
  },
  list: {
    paddingTop: "10px",
  },
  listItemText: {
    display: "flex",
    alignItems: "center",
  },
  listItemTextSecondary: {
    paddingLeft: "10px",
    color: "#555555",
  },
  link: {
    textDecoration: `none`,
    color: "inherit",
  },
}))

const Sidebar = ({ mobileOpen, setMobileOpen }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query AllArticles {
      allMarkdownRemark(filter: { frontmatter: { type: { eq: "week" } } }) {
        edges {
          node {
            id
            frontmatter {
              week
              path
              title
              author
              subtitle
            }
          }
        }
      }
    }
  `)
  const isMobile = useIsMobile()

  const weeks = data.allMarkdownRemark.edges
    .map(e => ({
      id: e.node.id,
      ...e.node.frontmatter,
    }))
    .sort((a, b) => a.week - b.week)

  const locked = []

  return (
    <Drawer
      className={classes.drawer}
      variant={isMobile ? "temporary" : "permanent"}
      classes={{
        paper: classes.drawerPaper,
      }}
      color="secondary"
      PaperProps={{ elevation: 20 }}
      ModalProps={{
        keepMounted: isMobile, // Better open performance on mobile.
      }}
      open={!isMobile || mobileOpen}
      onClose={() => setMobileOpen(!mobileOpen)}
    >
      <Toolbar className={classes.toolbar}>
        <img className={classes.logo} src={logo} alt="WOOFCODE logo" />
      </Toolbar>
      <div className={classes.drawerContainer}>
        <List className={classes.list}>
          <Link className={classes.link} to="/learn">
            <ListItem button>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
          <Link className={classes.link} to="/syllabus">
            <ListItem button>
              <ListItemText primary="Syllabus" />
            </ListItem>
          </Link>
          <Link className={classes.link} to="/calendar">
            <ListItem button>
              <ListItemText primary="Calendar" />
            </ListItem>
          </Link>
          <a
            className={classes.link}
            target="_blank"
            href="https://discord.gg/9vXfgcB"
          >
            <ListItem button>
              <ListItemText primary="Ask questions" />
            </ListItem>
          </a>
          <a
            className={classes.link}
            target="_blank"
            href="https://ko-fi.com/adamjanes"
          >
            <ListItem button>
              <ListItemText primary="Buy me a coffee" />
            </ListItem>
          </a>
          <Divider className={classes.divider} variant="inset" />
          {weeks.map(week =>
            locked.find(d => d === week.week) === undefined ? (
              <Link key={week.path} className={classes.link} to={week.path}>
                <ListItem button key={week.id}>
                  <ListItemText
                    classes={{
                      root: classes.listItemText,
                      secondary: classes.listItemTextSecondary,
                    }}
                    primary={week.title}
                    secondary={week.subtitle || ""}
                  />
                </ListItem>
              </Link>
            ) : (
              <ListItem button disabled key={week.id}>
                <ListItemText
                  classes={{
                    root: classes.listItemText,
                    secondary: classes.listItemTextSecondary,
                  }}
                  primary={week.title}
                  secondary={week.subtitle || ""}
                />
              </ListItem>
            )
          )}
        </List>
      </div>
    </Drawer>
  )
}

export default Sidebar
