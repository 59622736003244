import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  makeStyles,
  Toolbar,
  AppBar,
  CssBaseline,
  IconButton,
  Container,
} from "@material-ui/core"
import { Menu } from "@material-ui/icons"

import Sidebar from "../components/Sidebar"
import logo from "../images/logo-white.png"
import useIsMobile from "../hooks/useIsMobile"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  main: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%"
  },
  appbar: {
    display: "flex",
    flexDirection: "row",
  },
  appbarContainer: {
    alignItems: "center",
    display: "flex",
    height: "64px",
    justifyContent: "flex-start",
  },
  toolbar: {
    minHeight: "84px",
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  logo: {
    marginBottom: 0,
    width: "200px",
  },
  content: {
    padding: theme.spacing(3),
    paddingTop: 0,
    flex: 1,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    backgroundColor: "#333333",
  },
}))

const BookletLayout = ({ children }) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const [mobileOpen, setMobileOpen] = useState(false)

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <main className={classes.main}>
        {isMobile && (
          <AppBar color="primary" className={classes.appbar}>
            <Container className={classes.appbarContainer}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setMobileOpen(!mobileOpen)}
                className={classes.menuButton}
              >
                <Menu />
              </IconButton>
              <img className={classes.logo} src={logo} alt="WOOFCODE logo" />
            </Container>
          </AppBar>
        )}
        <Toolbar className={classes.toolbar} />
        <div className={classes.content}>{children}</div>
      </main>
    </div>
  )
}

BookletLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BookletLayout
